import { createGlobalStyle } from 'styled-components';
import BackgroundImage from './assets/img/background.jpg';

const GlobalStyles = createGlobalStyle`
  /* Reset básico */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    font-family: 'Afacad Flux', sans-serif;
    background-color: #f5f5f5;
    color: #fff;

    /* Adicionando imagem de background */
    background-image: url(${BackgroundImage}); /* Caminho da sua imagem */
    background-size: cover; /* Cobrir a tela inteira */
    background-repeat: no-repeat; /* Não repetir a imagem */
    background-position: center; /* Centralizar a imagem */
    background-attachment: fixed; /* Manter a imagem fixa enquanto o usuário rola a página */
  }

  body {
    line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1.5rem;
    font-family: 'Cormorant Garamond', serif;
    color: #FFF;
  }

  p {
    margin-bottom: 1rem;
    color: #CCC;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #007bff;
    }
  }

  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  button {
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #333;
    }
  }

  /* Definindo contêiner principal */
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  /* Estilos responsivos */
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.75rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export default GlobalStyles;
