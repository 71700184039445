import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.main`
  padding: 5rem 1rem;
  color: #fff;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  max-width: 600px;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #1a1a1a;
  text-align: center;
`;

const SectionTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #FFF;
`;

const SectionText = styled.p`
  font-size: 1.2rem;
  color: #ccc;
  margin-bottom: 3rem;
`;

const ContactInfo = styled.div`
  margin-bottom: 2rem;
`;

const InfoItem = styled.p`
  font-size: 1.1rem;
  color: #ddd;
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #c50;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f50;
    color: #fff;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const SocialIcon = styled.a`
  font-size: 1.5rem;
  color: #ccc;
  transition: color 0.3s;

  &:hover {
    color: #fff;
  }
`;

const Contato = () => (
  <MainContainer>
    <ContentWrapper>

      <SectionText>
        Entre em contato com o Lobby Tattoo para agendar sua sessão ou tirar dúvidas. <br/>Estamos aqui para ajudar e garantir que sua experiência seja incrível!
      </SectionText>

      <ContactInfo>
        <InfoItem>📍 Endereço: R. Conselheiro Laurindo, 490 <br/> sala 102, 10º andar - Centro, Curitiba - PR</InfoItem>
        <InfoItem>📞 Telefone: (41) 99936-1643</InfoItem>
        <InfoItem>📧 Email: contato@lobbytattoo.com.br</InfoItem>
      </ContactInfo>

      <CTAButton href="https://wa.me/41999361643" target="_blank">
        Agende sua visita
      </CTAButton>

      <SocialLinks>
        <SocialIcon href="https://www.facebook.com/lobbytattoo" target="_blank" aria-label="Facebook">
          <i className="fab fa-facebook"></i>
        </SocialIcon>
        <SocialIcon href="https://www.instagram.com/lobbytattoo" target="_blank" aria-label="Instagram">
          <i className="fab fa-instagram"></i>
        </SocialIcon>
        <SocialIcon href="mailto:contato@lobbytattoo.com.br" aria-label="Email">
          <i className="fas fa-envelope"></i>
        </SocialIcon>
        <SocialIcon href="https://wa.me/41999361643" target="_blank" aria-label="WhatsApp">
          <i className="fab fa-whatsapp"></i>
        </SocialIcon>
      </SocialLinks>
    </ContentWrapper>
  </MainContainer>
);

export default Contato;
