import React from 'react';
import styled from 'styled-components';
import BannerImg from '../assets/img/banner.jpg';

const MainContainer = styled.main`
  padding: 5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const BannerImage = styled.img`
  width: 80%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const SectionText = styled.p`
  font-size: 1.2rem;
  color: #fff;
  max-width: 800px;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #c50;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f50;
    color: #fff;
  }
`;

const Home = () => (
  <>
    <MainContainer>
      <BannerImage src={BannerImg} alt="Banner Lobby Tattoo" />
      <SectionText>
        Bem-vindo ao Lobby Tattoo, um estúdio de tatuagem em Curitiba onde arte e
        qualidade se encontram. <br/>
        Com mais de 20 anos de experiência, oferecemos
        tatuagens personalizadas e trabalhos de cobertura, sempre com um toque único
        e criativo. Venha transformar suas ideias em uma obra de arte, num ambiente
        seguro e acolhedor.
      </SectionText>
      <CTAButton href="https://wa.me/41999361643" target="_blank">
        Agende sua visita
      </CTAButton>
    </MainContainer>
  </>
);

export default Home;
