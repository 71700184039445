import React from 'react';
import styled from 'styled-components';
import UnderConstructionIcon from '../assets/img/underConstruction.webp'

const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
margin-top: 50px;
  font-size: 4rem;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  width: 80vw;
`;

const Icon = styled.img`
  width: 150px;
  margin-bottom: 2rem;
`;

const UnderConstruction = () => (
  <PageContainer>
    <Title>EM CONSTRUÇÃO</Title>
    <Subtitle>Nosso portal está sendo cuidadosamente desenhado. 
        Em breve, traremos novas experiências e tatuagens que contarão a sua história na pele!</Subtitle>
    <Icon src={UnderConstructionIcon} alt="Em Construção" />

  </PageContainer>
);

export default UnderConstruction;
