import React from 'react';
import styled from 'styled-components';
import tattoo1 from '../assets/img/tattoo1.jpg';
import tattoo2 from '../assets/img/tattoo2.jpg';
import tattoo3 from '../assets/img/tattoo3.jpg';

const MainContainer = styled.main`
  padding: 5rem 3rem;
  background-color: #1a1a1a;
  color: #fff;
`;

const SectionTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #FFF;
`;

const SectionText = styled.p`
  font-size: 1.2rem;
  color: #ccc;
  text-align: center;
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const GalleryContainer = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const GalleryItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.2rem;

  ${GalleryItem}:hover & {
    opacity: 1;
  }
`;

const TestimonialsContainer = styled.div`
  margin-top: 4rem;
  text-align: center;
`;

const Testimonial = styled.p`
  font-style: italic;
  font-size: 1rem;
  color: #bbb;
  max-width: 600px;
  margin: 0 auto 1.5rem;
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  color: #fff;
  background-color: #ff6f61;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 3rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff8a75;
  }
`;

const Portfolio = () => (
  <MainContainer>
    <SectionText>
      Explore alguns dos trabalhos mais recentes do Lobby Tattoo. Cada tatuagem é única e personalizada para atender aos desejos e estilo de cada cliente. Abaixo, você encontrará exemplos de realismo, old school, blackwork e muito mais. Encontre inspiração e agende sua consulta para transformar suas ideias em arte.
    </SectionText>

    <GalleryContainer>
      <GalleryItem>
        <Image src={tattoo1} alt="Realismo - Tatuagem em preto e cinza" />
        <ImageOverlay>Realismo</ImageOverlay>
      </GalleryItem>
      <GalleryItem>
        <Image src={tattoo2} alt="Old School - Tatuagem colorida" />
        <ImageOverlay>Old School</ImageOverlay>
      </GalleryItem>
      <GalleryItem>
        <Image src={tattoo3} alt="Blackwork - Tatuagem em preto sólido" />
        <ImageOverlay>Blackwork</ImageOverlay>
      </GalleryItem>
      {/* Adicione mais itens da galeria conforme necessário */}
    </GalleryContainer>

    <TestimonialsContainer>
      <Testimonial>"Excelente trabalho! Amei o resultado e o atendimento foi ótimo. Recomendo muito!" - Julia S.</Testimonial>
      <Testimonial>"Um estúdio de confiança e profissionalismo. Minha tatuagem ficou perfeita!" - Carlos M.</Testimonial>
      <Testimonial>"Experiência incrível. Me senti super à vontade e o trabalho ficou sensacional!" - Larissa F.</Testimonial>
    </TestimonialsContainer>

    <div style={{ textAlign: 'center', marginTop: '3rem' }}>
      <CTAButton href="https://wa.me/SeuNumeroDeWhatsApp" target="_blank" rel="noopener noreferrer">
        Agende sua consulta agora
      </CTAButton>
    </div>
  </MainContainer>
);

export default Portfolio;
