import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Portfolio from './pages/Portfolio';
import Contato from './pages/Contato';
import Header from './components/Header';
import Footer from './components/Footer';
import GlobalStyles from './GlobalStyles';
import UnderConstruction from './pages/UnderConstruction';

const App = () => (
  <Router>
    <GlobalStyles />
    <Header /> {/* Header aplicado a todas as páginas */}
    <Routes>
      {/* 
      <Route path="/" element={<UnderConstruction />} />
      */}
      <Route path="/" element={<Home />} />
      <Route path="/sobre" element={<Sobre />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/contato" element={<Contato />} />
    </Routes>
    <Footer /> {/* Footer aplicado a todas as páginas */}
  </Router>
);

export default App;
