import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LogoImage from '../assets/img/logo.png';

const Navbar = styled.nav`
  background-color: #000;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavLink = styled.li`
  margin-left: 1rem;
  
  a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: #F33;
    }
  }
`;

const Header = () => (
  <Navbar>
    <Container>
      <Link to="/">
        <Logo src={LogoImage} alt="Lobby Tattoo Logo" />
      </Link>
      <NavLinks>
        <NavLink><Link to="/">HOME</Link></NavLink>
        <NavLink><Link to="/sobre">SOBRE</Link></NavLink>
        <NavLink><Link to="/portfolio">PORTFOLIO</Link></NavLink>
        <NavLink><Link to="/contato">CONTATO</Link></NavLink>
      </NavLinks>
    </Container>
  </Navbar>
);

export default Header;
