import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.main`
  padding: 5rem 3rem;
`;

const SectionTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #FFF;
`;

const SectionText = styled.p`
  font-size: 1.2rem;
  color: #fff;
  line-height: 1.6;
  max-width: 800px;
  margin-bottom: 1.5rem;
`;

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const IframeWrapper = styled.div`
  flex: 1;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
`;

const Sobre = () => (
  <MainContainer>
    <SectionTitle>LOBBY TATTOO</SectionTitle>
    <SectionText>
      Localizado no coração de Curitiba, o Lobby Tattoo é mais do que um estúdio de tatuagem – é um espaço onde arte, técnica e cuidado se unem para proporcionar uma experiência única aos nossos clientes. Fundado por Marcos Cruz, tatuador com mais de 20 anos de experiência no mercado, o estúdio se destaca pela dedicação à arte da tatuagem personalizada e trabalhos de cobertura, sempre com foco em um atendimento de excelência.
    </SectionText>
    <SectionText>
      Nosso objetivo é transformar suas ideias em arte permanente, com segurança, profissionalismo e atenção aos detalhes. Aqui, cada tatuagem é tratada como um projeto exclusivo, feito sob medida para refletir a personalidade e o estilo de cada cliente. No Lobby Tattoo, utilizamos apenas materiais de alta qualidade e seguimos rigorosos protocolos de higiene e segurança, assegurando um ambiente confortável e confiável.
    </SectionText>
    <SectionText>
      Além das tatuagens, oferecemos serviços de aplicação de piercing, conduzidos com o mesmo padrão de qualidade e segurança. Seja sua primeira tatuagem ou parte de uma coleção, nossa equipe está pronta para tornar sua experiência inesquecível. Agende uma visita e venha conhecer o Lobby Tattoo, onde sua pele encontra a arte.
    </SectionText>


    <MapContainer>
      <IframeWrapper>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!4v1730993569711!6m8!1m7!1ssgxIzJivt2R6owTDgHlWBw!2m2!1d-25.43104886152834!2d-49.26451088338941!3f268.268954824048!4f3.237921057664437!5f0.7820865974627469" 
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
          title="Lobby Tattoo Street View"
        ></iframe>
      </IframeWrapper>
      <IframeWrapper>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.2134373098934!2d-49.26733408938505!3d-25.43112687746748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce6f2f8803429%3A0x137ef04bd0946b1e!2sLobby%20Tattoo%20-%20Est%C3%BAdio%20de%20Tatuagem%20em%20Curitiba!5e0!3m2!1spt-BR!2sbr!4v1730993411621!5m2!1spt-BR!2sbr"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Lobby Tattoo Location"
        ></iframe>
      </IframeWrapper>

    </MapContainer>

    <SectionText>
    R. Conselheiro Laurindo, 490 <br/>
    sala 102 - 10º andar <br/>
    Centro, Curitiba - PR, 80060-100
    </SectionText>

  </MainContainer>

);

export default Sobre;
